import { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
const ModeExploitation = ({register,chercher,paiement,setPaiement,handleInput,errors,isfound,setIsfound,autorisations,msgErr,setMsgErr}) => {
    const [message,setMessage] = useState();
    const now = new Date();
    useEffect(() => {
      
        // if( paiement.cartegrise_id !== 0 ){
        //     if(!moment(now).isAfter(paiement.dateExpAu,'year')){
        //       setMsgErr("La date de l'autorisation de transport n'est pas expirée."); 
        //       setIsfound(false);return; 
        //     }else setIsfound(true); 
         
        // }
        if(paiement.numChassis === ""){
            return;
         }
        // if(paiement.nomCategorie === '' || typeof paiement.nomCategorie === 'undefined'){
        //   setMessage("Nous n'avons pas trouvé de carte grise correspondante.");
        // }else setIsfound(true);  
        
       if(paiement.modeExpChange === 'Personnel')
          setPaiement({...paiement,autorisation_id:0}); 
   
    },[])
   // console.log(paiement)
    return (
      <>
        <div className="input-group">
          <label>Numéro de Chassis
            <div className="search-field">
                <input type="text" placeholder="Saisir le Numéro de Chassis" 
                        name="numChassis" value={typeof paiement.numChassis !== 'number'?paiement.numChassis:'' } 
                        {...register('numChassis', {
                            onChange: (e) => {
                            handleInput(e)
                            },
                            required:true,minLength:8,maxLength:25},
                            
                        )}
                />
                <div className="search-icon" onClick={chercher}>
                    <FiSearch />
                </div>
            </div>
            { msgErr && <span className="error-msg">{msgErr}</span>}
            {errors.numChassis && errors.numChassis?.type === "required" && (
                    <span className="error-msg">Le numéro de Chassis est obligatoire.</span>
                )}
            {errors.numChassis && errors.numChassis?.type === "minLength" && (
                <span className="error-msg">Le caractère minimum est huit (8).</span>
            )}
             {errors.numChassis && errors.numChassis?.type === "maxLength" && (
                <span className="error-msg">Le caractère maximal est vingt cinq (25).</span>
             )}    
            </label>
            <label>{paiement.typeClient === 'Société' ? 'Nom de la Société' :'Prenom et Nom du client'}
               <strong style={{marginTop:'12px'}}>{paiement.fullName? paiement.fullName:'Non Fournie'}</strong>
            </label>
            <label>Téléphone
               <strong style={{marginTop:'12px'}}>{paiement.telephone?paiement.telephone:'Non Fournie'}</strong>
            </label>
        </div>
        <div className='input-group'>
            <label>Mode d'Immatriculation
                <strong style={{marginTop:'12px'}}>{paiement.modeImma?paiement.modeImma:'Non Fournie'}</strong>
            </label>
        
            <label>Categorie de Vehicule
                <strong style={{marginTop:'12px'}}>{paiement.nomCategorie?paiement.nomCategorie:'Non Fournie'}</strong>
            </label>
            <label>Mode d'exploitation actuel
               <strong style={{marginTop:'12px'}}>{paiement.modeExp?paiement.modeExp:'Non Fournie'}</strong>
            </label>
            {isfound && <label>
              Mode d'exploitation
                <select
                  name="modeExpChange"
                  id="modeExpChange"
                  {...register("modeExpChange", {
                      onChange: (e) => {
                        handleInput(e);
                      },
                      validate: (value) => value != "",
                  })}
                  value={paiement.modeExpChange}
                  >
                  <option value="">Selectionner l'Usage</option>
                  {(paiement.modeExp !== "Usage Personnel" && paiement.modeExp !== "Personnel") && <option value="Personnel">Usage personnel</option>}
                  {paiement.modeExp !== "Transport" && <option value="Transport">Transport</option>}
                  </select>
                  {errors.modeExpChange && (
                  <span className="error-msg">Veuillez choisir le mode d'exploitation.</span>
                  )}
             </label>}
        </div>  
        {(paiement.modeExpChange === "Transport" && isfound)&&
          <div className='input-group'>
            <label>Autorisation de Transport
                <select 
                    value={paiement.autorisation_id}
                    name="autorisation_id"
                    id="autorisation_id"
                    {...register("autorisation_id", {
                    onChange: (e) => {
                        handleInput(e);
                    },
                    validate: (value) => value != 0,
                    })} 
                >
                <option value={0}>Autorisation de Transport</option>
                {autorisations &&
                  autorisations.map((autorisation) => (
                    <option
                      key={autorisation.autorisation_id}
                      value={autorisation.autorisation_id}
                    >
                      {autorisation.nomAutorisation}
                    </option>
                  ))}
                </select>
                {errors.autorisation_id && (
                <span className="error-msg">L'autorisation de transport est obligatoire.</span>
                )}
            </label> 
            <div></div> 
          
            <div></div>
         </div>}
      </>
    );
}
export default ModeExploitation;