import Box from "@mui/material/Box";
import { DataGridPremium, GridToolbar, frFR } from "@mui/x-data-grid-premium";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { commission, formatStringNumber, isEmpty } from "../../services/Helpers/fonctions";
import moment from "moment";
import 'moment/locale/fr';
import { ToWords } from 'to-words';
import Api from "../../services/Api";
import DocumentTitle from "../../components/DocumentTitle/DocumentTitle";

const columns = [
  {
    field: "reference",
    headerName: "Numero de reference",
    flex: 1,
    minWidth: 180,
  },
  {
    field: "numChassis",
    headerName: "Numero de chassis",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "modeExp",
    headerName: "Mode d'exploitation",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "typeClient",
    headerName: "Type Client",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "fullName",
    headerName: "Client",
    flex: 1,
    minWidth: 150,
  },
  // {
  //   field: "fullName",
  //   headerName: "Client",
  //   description: "Le Nom du CLient.",
  //   sortable: false,
  //   flex: 1,
  //   minWidth: 150,
  //   valueGetter: (params) =>
  //     `${params.row.firstName || ""} ${params.row.lastName || ""}`,
  // },
  {
    field: "TypePaiement",
    headerName: "Type Paiement",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "sumMontant",
    headerName: "Montant(FG)",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "created_at",
    headerName: "Date",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "options",
    headerName: "Options",
    sortable: false,
    flex: 1,
    minWidth: 150,
    renderCell: (params) => {
      const id = params.id;
      return (
        <div className="options">
          <Link to={`/payment/invoice/${id}`}>
            <button>Voir facture</button>
          </Link>
        </div>
      );
    },
  },
];
let nbrePaiement = 0
const PaymentListOther = () => {
  const api = new Api();
  const [paiementData,setPaiementData] = useState([]);
  const[globalMontant,setGlobalMontant] = useState(0);
  const toWords = new ToWords({
    localeCode: 'fr-FR',
    converterOptions: {
      currency: false,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
    }
  });
  const location = useLocation();
  useEffect(() => {
    api.apiData('get','/paiement/getPaiementsOther').then((resp) => {
      nbrePaiement = 0;
      if(resp.status === 200){
        let commission1 = 0;let globalsom = 0;
       // if( isEmpty(location?.state) ){
          setPaiementData(resp.paiements.map((paiement) => {
            commission1 = commission(paiement.categorie_id)
        
    
            let montautorisation = 0;let modeImm = "Immatriculation";
    
            if(paiement.montantautorisation != null)
                montautorisation = paiement.montantautorisation;
                let sumMontant = parseFloat(paiement?.montantcartegrise !=null ?paiement?.montantcartegrise:0) + parseFloat(paiement?.montantvignette != null?paiement?.montantvignette:0) + parseFloat(montautorisation) + parseFloat(commission1);
            if(paiement.modeImma != 1)
              modeImm = "RéImmatriculation";
            globalsom = sumMontant + parseFloat(globalsom);
            nbrePaiement = nbrePaiement + 1;
            const typePaiement = paiement.type_document.charAt(0).toUpperCase() + paiement.type_document.slice(1);
      
            return {
              id:paiement.paiement_id,
              typeClient:paiement.typeClient,
              modeExp:paiement.modeExp,
              fullName:paiement.fullName,
              reference:paiement.reference,
              TypePaiement:typePaiement === "Autorisation" ? "Autorisation de Transport":typePaiement,
              created_at:moment(paiement.created_at).format('Do MMMM  YYYY H:m'),
              sumMontant:sumMontant,
              numChassis:paiement.chassis
            }
          }));
       // }
        // else{
        //     const status = location.state.filter_id;
        //     if(status == 1){
        //         setPaiementData(resp.paiements.map((paiement) => {
        //           commission1 = commission(paiement.categorie_id)
              
          
        //           let montautorisation = 0;let modeImm = "Immatriculation";
          
        //           if(paiement.montantautorisation != null)
        //               montautorisation = paiement.montantautorisation;
        //           let sumMontant = parseFloat(paiement?.montantcartegrise !=null ?paiement?.montantcartegrise:0) + parseFloat(paiement?.montantvignette != null?paiement?.montantvignette:0) + parseFloat(montautorisation) + parseFloat(commission1);
        //           if(paiement.modeImma != 1)
        //             modeImm = "RéImmatriculation";
        //           globalsom = sumMontant + parseFloat(globalsom);
        //           nbrePaiement = nbrePaiement + 1;
                
        //           return {
        //             id:paiement.paiement_id,
        //             typeClient:paiement.typeClient,
        //             modeExp:paiement.modeExp,
        //             fullName:paiement.fullName,
        //             reference:paiement.reference,
        //             modeImm:modeImm,
        //             created_at:moment(paiement.created_at).format('Do MMMM  YYYY H:m'),
        //             sumMontant:sumMontant,
        //             numChassis:paiement.chassis
        //           }
        //         }));
        //     }else if(status == 2){
        //       setPaiementData(resp.paiements.filter(p => p.typeClient === "Particulier").map((paiement) => {
        //         commission1 = commission(paiement.categorie_id)
            
        //         let montautorisation = 0;let modeImm = "Immatriculation";
        
        //         if(paiement.montantautorisation != null)
        //             montautorisation = paiement.montantautorisation;
        //         let sumMontant = parseFloat(paiement.montantcartegrise) + parseFloat(paiement.montantvignette) + parseFloat(montautorisation) + parseFloat(commission1);
        //         if(paiement.modeImma != 1)
        //           modeImm = "RéImmatriculation";
        //         globalsom = sumMontant + parseFloat(globalsom);
        //         nbrePaiement = nbrePaiement + 1;
              
        //         return {
        //           id:paiement.paiement_id,
        //           typeClient:paiement.typeClient,
        //           modeExp:paiement.modeExp,
        //           fullName:paiement.fullName,
        //           reference:paiement.reference,
        //           modeImm:modeImm,
        //           created_at:moment(paiement.created_at).format('Do MMMM  YYYY H:m'),
        //           sumMontant:sumMontant,
        //           numChassis:paiement.chassis
        //         }
        //       }));
        //     }else if(status == 3){
        //       setPaiementData(resp.paiements.filter(p => p.typeClient === "Société").map((paiement) => {
        //         commission1 = commission(paiement.categorie_id)
            
        //         let montautorisation = 0;let modeImm = "Immatriculation";
               
        //         if(paiement.montantautorisation != null)
        //             montautorisation = paiement.montantautorisation;
        //         let sumMontant = parseFloat(paiement?.montantcartegrise !=null ?paiement?.montantcartegrise:0) + parseFloat(paiement?.montantvignette != null?paiement?.montantvignette:0) + parseFloat(montautorisation) + parseFloat(commission1);
        //         if(paiement.modeImma != 1)
        //           modeImm = "RéImmatriculation";
        //         globalsom = sumMontant + parseFloat(globalsom);
        //         nbrePaiement = nbrePaiement + 1;
                
        //         return {
        //           id:paiement.paiement_id,
        //           typeClient:paiement.typeClient,
        //           modeExp:paiement.modeExp,
        //           fullName:paiement.fullName,
        //           reference:paiement.reference,
        //           modeImm:modeImm,
        //           created_at:moment(paiement.created_at).format('Do MMMM  YYYY H:m'),
        //           sumMontant:sumMontant,
        //           numChassis:paiement.chassis
        //         }
        //       }));
        //     }
        // }
        setGlobalMontant(globalsom);
      }
    });
  },[location.state]);
  return (
    <div className="payment-list page">
      <DocumentTitle title="Liste des paiements - Autres paiements" />
      <div className="header">
        <h3>Liste des autres paiements</h3>
         <p>{nbrePaiement} paiements | <span><strong>Montant Global : </strong> { toWords.convert(globalMontant)+" Francs Guinéens"} (<strong>{formatStringNumber(globalMontant) + " fg"}</strong>)</span></p> 

        
      </div>
      <div className="array">
        <Box sx={{ height: 579, width: "100%" }}>
          <DataGridPremium
            sx={{ borderRadius: 0}}
            density="compact"
            components={{ Toolbar: GridToolbar }}
            rows={paiementData}
            columns={columns}
            autoPageSize
            pagination
            disableSelectionOnClick
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          />
        </Box>
      </div>
    </div>
  );
};

export default PaymentListOther;
