import { useContext, useState } from "react";
import { paymentSteps } from "../../utils/texts";
import Stepper from "../../components/Stepper/Stepper";
import StepOne from "../../components/OtherPaymentSteps/StepOne";
import StepTwo from "../../components/OtherPaymentSteps/StepTwo";
import StepThree from "../../components/OtherPaymentSteps/StepThree";
import { ElementContext, UserContext } from "../../services/Context/Context";
import { useEffect } from "react";

const OtherPayment = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [isfound,setIsfound] = useState();
  const[msgErr,setMsgErr] = useState([]);
  const [selectedCartegrise_id,setSelectedCartegrise_id] = useState(0);
  const [selectedAutorisation_id,setSelectedAutorisation_id] = useState(0);
  const [documentError,setDocumentError] = useState();
  const {agence} = useContext(UserContext);
  const [paiement,setPaiement] = useState({
    paiement_id:'',
    numChassis:'',
    numImma:'',
    document:'',
    operation:'',
    telephone:'',
    fullName:'',
    nif:'',
    agence_id:agence?agence.agence_id:0,
    quartier_id:agence?agence.quartier_id:0,
    autorisation_id: 0,
    categorie_id:0,
    cartegrise_id:0,
    vignette_id:0,
    typeClient:'',
    modeExp:'',
    reference:'',
    nomCategorie:'',
    vignette:'',
    modeImma:'',
    newautorisation_id:false,
    autorisation:'',
    cartegrise:'',
    isRenouvellement1:true,
    isRenouvellement2:false

  });
  const [paiementInfo,setPaiementInfo] = useState();
  const nextStep = () => {
    setDocumentError('');
    if(paiement.isRenouvellement1){
      if(paiement.selectdocuments.length === 0 && paiement.document === 'duplicate'){
        setDocumentError("Veuillez choisir aumoin un document");return;
      }
    }
    setActiveStep(activeStep + 1)
  }

  const prevStep = () => {
    setActiveStep(activeStep - 1)
  }
 useEffect(() => {
  if(agence)
    setPaiement({...paiement,agence_id:agence.agence_id,quartier_id:agence.quartier_id});
 },[agence])
  return (
    <div className="payment page">
      <div className="header">
        <h3>Autres types de Paiement</h3>
        <p>Suivez les trois étapes suivantes pour créer un nouveau paiement.</p>
      </div>
      <div className="payment-zone">
        <div className="payment-steps">
          <Stepper steps={paymentSteps} activeStep={activeStep} />
        </div>
        <div className="payment-form">
          {activeStep === 1 && <StepOne nextStep={nextStep} prevStep={prevStep} paiement={paiement} 
            setPaiement={setPaiement} isfound={isfound} setIsfound={setIsfound} setMsgErr={setMsgErr} msgErr={msgErr}
            selectedCartegrise_id={selectedCartegrise_id} setSelectedCartegrise_id={setSelectedCartegrise_id} 
            selectedAutorisation_id={selectedAutorisation_id} setSelectedAutorisation_id={setSelectedAutorisation_id}
            setDocumentError = {setDocumentError} documentError={documentError}
            />}
          {activeStep === 2 && <StepTwo nextStep={nextStep} activeStep = {activeStep} prevStep={prevStep} paiement={paiement} setPaiement={setPaiement} activeStep={activeStep} setActiveStep={setActiveStep}  
            paiementInfo={paiementInfo} setPaiementInfo={setPaiementInfo} setMsgErr={setMsgErr} />}
          {activeStep === 3 && <StepThree nextStep={nextStep} prevStep={prevStep} 
          paiementInfo={paiementInfo} setPaiementInfo={setPaiementInfo} />}
        </div>
      </div>
    </div>
  );
};

export default OtherPayment;
