import { useContext, useState } from "react";
import { paymentSteps } from "../../utils/texts";
import Stepper from "../../components/Stepper/Stepper";
import StepOne from "../../components/OtherPaymentSteps/StepOne";
import StepTwo from "../../components/OtherPaymentSteps/StepTwo";
import StepThree from "../../components/OtherPaymentSteps/StepThree";
import { ElementContext, UserContext } from "../../services/Context/Context";
import { useEffect } from "react";

const OtherPaymentResubmission = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [isfound,setIsfound] = useState();
  const {agence} = useContext(UserContext);
  const [paiement,setPaiement] = useState({
    paiement_id:'',
    numChassis:'',
    numImma:'',
    document:'',
    operation:'',
    telephone:'',
    fullName:'',
    nif:'',
    agence_id:agence?agence.agence_id:0,
    quartier_id:agence?agence.quartier_id:0,
    autorisation_id: 0,
    categorie_id:0,
    cartegrise_id:0,
    vignette_id:0,
    typeClient:'',
    modeExp:'',
    reference:'',
    nomCategorie:'',
    vignette:'',
    modeImma:'',
    newautorisation_id:false,
    autorisation:'',
    cartegrise:''

  });
  const [paiementInfo,setPaiementInfo] = useState();
  const nextStep = () => {
    setActiveStep(activeStep + 1)
  }

  const prevStep = () => {
    setActiveStep(activeStep - 1)
  }
 useEffect(() => {
  if(agence)
    setPaiement({...paiement,agence_id:agence.agence_id,quartier_id:agence.quartier_id});
 },[agence])
  return (
    <div className="payment page">
      <div className="header">
        <h3>Resoumission autre paiement</h3>
        <p>Suivez les trois étapes suivantes pour resoumettre votre paiement.</p>
      </div>
      <div className="payment-zone">
        <div className="payment-steps">
          <Stepper steps={paymentSteps} activeStep={activeStep} />
        </div>
        <div className="payment-form">
          {activeStep === 1 && <StepOne nextStep={nextStep} prevStep={prevStep} paiement={paiement} setPaiement={setPaiement} isfound={isfound} setIsfound={setIsfound} />}
          {activeStep === 2 && <StepTwo nextStep={nextStep} prevStep={prevStep} paiement={paiement} setPaiement={setPaiement} activeStep={activeStep} setActiveStep={setActiveStep}  paiementInfo={paiementInfo} setPaiementInfo={setPaiementInfo} />}
          {activeStep === 3 && <StepThree nextStep={nextStep} prevStep={prevStep} 
          paiementInfo={paiementInfo} setPaiementInfo={setPaiementInfo} />}
        </div>
      </div>
    </div>
  );
};

export default OtherPaymentResubmission;
