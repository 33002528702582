import { useRef, useState } from "react";
import { FaRegWindowClose } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import { useForm } from "react-hook-form";
import Api from "../../services/Api";
import { useRecoilState } from "recoil";
import { loadingState } from "../../recoil/atoms/loadingAtom";
import { objecttoFormData } from "../../services/Helpers/fonctions";
import toast from "react-hot-toast";
import Erreurs from "../Erreurs/Erreurs";
const useFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

  return [ htmlElRef, setFocus ] 
}
const NewWardModal = ({ isOpen, setIsOpen,prefectures,communes }) => {
  const modalRef = useRef();
  const [erreurs, setErreurs] = useState([]);
  const [selectedCommunes,setSelectedCommunes] = useState([]);
  const [ward,setWard] = useState({
    prefecture_id:0,
    commune_id:0,
    quartier:'',
  });
  const [agenceErreur,setAgenceErreur] = useState({ 
    error:'',
  });
  const [inputRef, setInputFocus] = useFocus();
  const { register, handleSubmit, reset,formState: { errors } } = useForm({defaultValues:{ward} });
  const [isLoading,setIsLoading] = useRecoilState(loadingState);
  const api = new Api();

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setIsOpen(false);
    }
  };

 const handleInput = (e) => {
    if(agenceErreur.error !== ''){
      setAgenceErreur({...agenceErreur,error:''})
    }
    if(e.target.name === 'prefecture_id'){
      const comms = communes.filter((c) => c.prefecture_id === parseInt(e.target.value));
       setSelectedCommunes(comms);
       setWard({...ward,[e.target.name]:e.target.value,commune_id:0});return ;
    }
    if(e.target.name === 'commune_id')
      setInputFocus();
    
    if(e.target.name === 'quartier'){
      setWard({...ward,[e.target.name]:e.target.value.toUpperCase()});return;
    }
    setWard({...ward,[e.target.name]:e.target.value});
 }
 const createQuartier = async () => {
  setAgenceErreur({...agenceErreur,error:''});setIsLoading(true);
  if(ward.quartier.length === 0){
    setAgenceErreur({...agenceErreur,error:"Le nom du quartier/district est obligatoire."}); setIsLoading(false);setInputFocus();return;}
  else if(ward.quartier.length < 4){
   setAgenceErreur({...agenceErreur,error:"Le nombre minimum de caractères pour le nom du quartier/district est trois (3)."});setIsLoading(false); setInputFocus();return;}
   var formData = objecttoFormData(ward);
   const {status,messages} = await api.apiData('post','decoupage/newQuartier',formData);
   if(status === 200){
     toast.success("Nouveau quartier/district ajouté avec succès.");setIsOpen(false);
   }else{
     setErreurs(messages);
   }
   setIsLoading(false);
  }

  return (
    <AnimatePresence>
      {isOpen ? (
        <div className="modal-component" onClick={closeModal} ref={modalRef}>
          <motion.div
            initial={{ opacity: 0, top: "30%" }}
            animate={{ opacity: 1, top: "50%" }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, top: "10%" }}
            className="modal"
          >
            <div className="close" onClick={() => setIsOpen(false)}>
              <FaRegWindowClose id="close-icon" />
            </div>
            <h4>Ajouter un quartier</h4>
            <form onSubmit={handleSubmit(createQuartier)}>
              <div className="input-group">
              <label>
                  Prefecture
                  <select name="prefecture_id" id="prefecture_id" value={ward.prefecture_id} 
                     {...register('prefecture_id', {
                      onChange: (e) => {
                        handleInput(e)
                      },
                      validate: (value) => value !=  0 
                      })
                    }
                   >
                    <option value={0}>Selectionner la Prefecture</option>
                    {prefectures && prefectures.map((prefecture) => {
                        return (<option value={prefecture.prefecture_id} key={Math.random()+"_"+prefecture.prefecture_id}>{prefecture.nom}</option>)
                    })}
                  </select>
                  {errors.prefecture_id && (
                      <span className="error-msg">Selectionner la Prefecture.</span>
                     )}
                </label>
                <label>
                  Commune
                  <select name="commune_id" id="commune_id"
                    {...register('commune_id', {
                      onChange: (e) => {
                        handleInput(e)
                      },
                      validate: (value) => value !=  0 
                      })
                    }
                    value={ward.commune_id}
                  >
                    <option value={0}>Selectionner la commune</option>
                    {selectedCommunes && selectedCommunes.map((commune) => {
                        return <option value={commune.commune_id} key={Math.random()+"_"+commune.commune_id}>{commune.nom}</option>
                    })}
                  </select>
                  {errors.commune_id && (
                      <span className="error-msg">Selectionner la  Commune .</span>
                     )}
                </label>
              </div>
              <div className="input-group">
                <label>
                  Quartier
                  <input type="text" name="quartier" id="quartier" placeholder="Quartier"
                     ref={inputRef}
                     onChange={handleInput}
                     value={ward.quartier}
                  />
                   {agenceErreur?.error.length > 0 &&(
                      <span role="alert" className="error-msg">
                        {agenceErreur.error}
                      </span>
                    )}
                </label>
              </div>
              <Erreurs validation = {erreurs} />
              <button type="submit">Enregistrer</button>
            </form>
          </motion.div>
        </div>
      ) : null}
    </AnimatePresence>
  );
};

export default NewWardModal;