import React, { useContext, useEffect, useState } from "react";
import { ElementContext } from "../../services/Context/Context";
import { expression, FirstUpperCase, initialized } from "../../services/Helpers/fonctions";
import { FiSearch } from 'react-icons/fi'
import Api from "../../services/Api";
import DocumentTitle from "../DocumentTitle/DocumentTitle";
import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import { loadingState } from "../../recoil/atoms/loadingAtom";
import Duplicata from "./Duplicata/Duplicate";
import Vignette from "./Vignette/Vignette";
import CarteGrise from "./CarteGrise/CarteGrise";
import Autorisation from "./Autorisation/Autorisation";
import ModeExploitation from "./ModeExploitation/ModeExploitation";
import moment from 'moment';
let NomAutorisation = '';
const StepOne = ({ nextStep,activeStep,prevStep,paiement,setPaiement,isfound,setIsfound,setMsgErr,msgErr ,selectedCartegrise_id,setSelectedCartegrise_id,
  selectedAutorisation_id,setSelectedAutorisation_id,setDocumentError,documentError}) => {
  
  const[categories,setCategories] = useState([]);
  const [cartegrises,setCartegrises] = useState([]);
  const [vignettes,setVignettes] = useState([]);
  const [autorisations,setAutorisations] = useState([]);
  const [isLoading,setIsLoading] = useRecoilState(loadingState);
  const [isCherche,setisCherche] = useState(false);
  const [typeVignettes, setTypeVignettes] = useState([]);
  const { register, handleSubmit, reset,formState: { errors } } = useForm({
    defaultValues:{
        paiement
    }
  });
  const { elementsData } = useContext(ElementContext);
  const api = new Api();

  const initialized2 = (operation = '') => {
        setPaiement({...paiement,
         operation:operation,
         modeExp:'',
         reference:'',
         nomCategorie:'',
         vignette: '',
         modeImma:'',
         fullName:'',
         telephone:'',
         autorisation_id:'',
         cartegrise_id:0,
         vignette_id:0,
         typeClient:'',
         cartegrise:'',
         nif:'',
         typeClient:'',
         NomAutorisation:'',
         dateExpAu:'',
         dateExpCg:'',
         dateExpVg:'',
         type_document:'',
         isRenouvellement1:true,
         isRenouvellement2:false
        });
  
  }
//   function Ascii(TextBox1) {
//     var ascii = '';
//     for (var i = 0, len = TextBox1.length; i < len; i++) {
//       ascii = ascii + TextBox1.charCodeAt(i);
//     }
//    return ascii;
// }
  const handleInput = (e) => {
    if(e.target.name === 'isRenouvellement1'){
      setPaiement({...paiement,[e.target.name]:true,isRenouvellement2:false,document:''})
      return;
    }
    else if(e.target.name === 'isRenouvellement2'){
      setPaiement({...paiement,[e.target.name]: true,isRenouvellement1:false,document:'Achat'})
      return;
    }
    // else if(e.target.name === 'ancienNumMat'){
    //   console.log(e.target.value.codePointAt(0))
    //   if( e.target.value.length >= 3 )
    //   if(e.target.value.codePointAt(e.target.value.length - 1) === 56)
    //      setPaiement({...paiement,[e.target.name]: e.target.value.toUpperCase()});
    //   else
    //     if(e.target.value.length === 7 && e.target.value.codePointAt(e.target.value.length - 1) !== 85){
    //       setPaiement({...paiement,[e.target.name]:  e.target.value + "-" });
    //     }
    //     else setPaiement({...paiement,[e.target.name]: e.target.value.toUpperCase()});
    //   else {
    //     setPaiement({...paiement,[e.target.name]: paiement.type_plaque + "-"});
        
    //   }
    //   return;
      
    // }
    else if(e.target.name === 'fullName'){
      setPaiement({...paiement,[e.target.name]: FirstUpperCase(e.target.value)})
      return;
    }
    else if( e.target.name === 'type_plaque'){
      setPaiement({...paiement,[e.target.name]: e.target.value,ancienNumMat:e.target.value +"-"})
      return;
    }
    else if(e.target.name === 'tel'){
      const re = /^[0-9\b]+$/;
      if(e.target.value.length <= 9 && re.test(e.target.value)){
        setPaiement({...paiement,[e.target.name]: e.target.value});
      }
      return;
    }
    if(e.target.name === 'numChassis'){
     if(e.target.value.length <= 17){
      setMsgErr([]);
       setPaiement({...paiement,
        [e.target.name]:e.target.value.toUpperCase()
      }); 
    }
     return;
    }
    if(e.target.name === 'nif'){
      setPaiement({...paiement,
        [e.target.name]:e.target.value.toUpperCase()
     });return
    }
    if(e.target.name === 'document'){
      setMsgErr('');
      setPaiement({...paiement,
        document:e.target.value,numChassis:''
     });return;
    }
 
    if(e.target.name === 'categorie_id'){
      setCartegrises([]);setVignettes([]);
      const cartegrise = elementsData.typeCarteGrise.filter(t => t.categorie_id == e.target.value && t.type === 1 );
      if(Array.isArray(cartegrise) && cartegrise.length > 0){
        setCartegrises(cartegrise.map((cg) =>{
          return expression(cg)
        }));
      }
      const vignette = elementsData.typeVignette.filter(v => v.typecg_id == e.target.value);
      if(Array.isArray(vignette) && vignette.length > 0){
          setVignettes(vignette);
      }
      setAutorisations(elementsData.autorisations.filter((p) => p.categorie_id === parseInt(e.target.value)));

      // setPaiement({...paiement,[e.target.name]:e.target.value,cartegrise_id:0,vignette_id:0,autorisation:0});
      setPaiement({...paiement,[e.target.name]:e.target.value,vignette_id:0,autorisation:0});
      return;
    }
    else if(e.target.name === 'vignette_id'){
      setPaiement({...paiement,[e.target.name]:e.target.value});
      return;
    }
    else if(e.target.name === 'typeClient'){
      setPaiement({...paiement,[e.target.name]:e.target.value,fullName:'',});
      return;
    }
    if(e.target.name === "modeExpChange"){
      setVignettes([]);setAutorisations([]);
      setPaiement({...paiement,
        [e.target.name]:e.target.value,vignette_id:0,autorisation_id:0
      });return;
    }
    setPaiement({...paiement,
      [e.target.name]:e.target.value
    });
    if(e.target.name === 'operation'){
      initialized(e.target.value)
    }


  }
  // const handleInput2 = (e) => {
  //   if(e.target.name === 'categorieCg'){
  //     setAutorisations(elementsData.autorisations.filter((p) => p.categorie_id === parseInt(e.target.value)));
  //     const vignette = elementsData.typeVignette.filter(v => v.typecg_id == e.target.value);
  //     if(Array.isArray(vignette) && vignette.length > 0){
  //         setVignettes(vignette);
  //     }
  //     setPaiement({...paiement,[e.target.name]:e.target.value,typeVignette:0,autorisation_id:0});
  //     return ;
  //  }
  // setPaiement({...paiement,[e.target.name]:e.target.value});
  // }
  const now = new Date();
  const chercher = async () => {
    setMsgErr([]);
     if(paiement.numChassis.length > 5){
        setIsLoading(true);
        const {status,success,messages,payment} = await api.apiData('get',`/paiement/getpaiementByNumChassis/${paiement.numChassis}`);
        console.log(payment)
        setIsLoading(false);   
        setIsfound(false);
        if(status === 200){
          if(Array.isArray(payment) && payment.length > 0){
            setIsfound(true);  setisCherche(true);
            
             const paym = payment[payment.length - 1];
             //console.log(paym)
           
             let modeImmatriculation = '';
             if(parseInt(paym.modeImma) === 1)
               modeImmatriculation = "Immatriculation";
             else  modeImmatriculation = "ReImmatriculation";  
            
             if(paym.autorisation_id !== 0){
                 const autorisation = elementsData.autorisations.filter(a => a.autorisation_id === parseInt(paym.autorisation_id))
                 if(autorisation.length > 0){
                    NomAutorisation = autorisation[0].nomAutorisation
                 }
             }
        
             let nomCartegrise = '';
        
             const cartegrises = elementsData.typeCarteGrise.filter(c => c.typecg_id === parseInt(paym.typeCg));
            const vignette = elementsData.typeVignette.filter((v) => v.typevg_id === parseInt(paym.typeVignette));
           
           
             if(Array.isArray(cartegrises) && cartegrises.length > 0)
                nomCartegrise = expression(cartegrises[0]).expression;
            
             setSelectedCartegrise_id(paym.typeCg);  
             setSelectedAutorisation_id(paym.autorisation_id); 
             setPaiement({...paiement,
               paiement_id:paym.paiement_id,
               fullName:paym.fullName,
               telephone:paym.tel,typeClient:paym.typeClient,
               modeExp:paym.modeExp ,
               telephone:paym.tel,
               reference:paym.reference,
               nomCategorie:paym.nomCategorie,
               vignette:vignette[0]?.nomType,
               modeImma:modeImmatriculation,
               autorisation_id:paym.autorisation_id,
               vignette_id:paym.typeVignette,
               categorie_id:paym.categorie_id,
               cartegrise:nomCartegrise,
               cartegrise_id:paym.typeCg,
               nif:paym.nif,
               NomAutorisation:NomAutorisation,
               date_exp:paym.date_exp,
               modeExpChange:'',
               type_document:paym.type_document,
               dateExpAu:paym.dateExpAu,
               dateExpCg:paym.dateExpCg,
               dateExpVg:paym.dateExpVg,
               selectdocuments:[],
               type_plaque:paym.type_plaque
              });
              if(paiement.document === "duplicata"){
                if(!moment(now).isAfter(paym.dateExpCg,'year') && paym.cartegrise_id === 0 && !moment(now).isAfter(paym.dateExpVg,'year') && !moment(now).isAfter(paym.dateExpAu,'year')){
                  setMsgErr("Nous n'avons pas trouvé de document correspondant.");setIsfound(false);
                }
                if((!moment(now).isAfter(paym.dateExpAu,'year')  || !moment(now).isAfter(paym.dateExpVg,'year')) && paym.cartegrise_id === 0){
                  setMsgErr("Nous n'avons pas trouvé de document correspondant.");setIsfound(false);
                }
                if(paym.typeCg === 0 && paym.typeVignette === 0 && paym.autorisation_id === 0 && !moment(now).isAfter(paym.dateExpAu,'year')){
                  setMsgErr("Nous n'avons pas trouvé de document correspondant.");setIsfound(false);
                }
            }
            if(paiement.document === "cartegrise" || paiement.document === "mutation"){
              //console.log(paym.categorie_id )

              if(paym.categorie_id === 0 || cartegrises.length === 0){
                setMsgErr("Nous n'avons pas trouvé de Carte de Grise correspondante.");setIsfound(false);
              }
              if(paiement.document === "cartegrise" )
                if( moment().diff(moment(paym.dateExpCg),'years') < 5 ){
                  setMsgErr("Impossible de Renouveller!Carte grise non expirée.");setIsfound(false);
                }
            }
         
            // if(paiement.document === 'changementmodeexp'){
            //   if( !moment(now).isAfter(paym.dateExpVg,'year') && !moment(now).isAfter(paym.dateExpAu,'year')){
            //     setMsgErr("Nous n'avons pas trouvé de document correspondant pour une date valide.");setIsfound(false);
            //   }
            // }
            if(paiement.document === "vignette"){
              if(paym.typeVignette === 0){
                setMsgErr("Vignette non trouvée.");setIsfound(false);
              }else if(!moment(now).isAfter(paym.dateExpVg,'year')){
                  setMsgErr("Impossible de Renouveller!Vignette non expirée.");setIsfound(false);
              }
            }
            if(paiement.document === "autorisation"){
              if(paym.autorisation_id === 0){
                setMsgErr("Autorisation de transport non trouvée.");setIsfound(false);
              }else if((!moment(now).isAfter(paym.dateExpAu,'year'))){
                setMsgErr("Impossible de Renouveller!Autorisation de Transport non expirée.");setIsfound(false);
             }
            }
            //console.log(paym)
            // if(paiement.document === "changementmodeexp"){
            //   if(paym.typeVignette === 0 && paym.autorisation_id === 0){
            //     setMsgErr("Nous n'avons pas trouvé de vignette et d'autorisation de transport correspondante.");setIsfound(false);
            //   }
            // }
         
              //console.log(paym) 
            
          }else{
            initialized2(paiement);
            if(paiement.document === 'vignette')
              setMsgErr("Nous n'avons pas trouvé de vignette correspondante.");  
            else if(paiement.document === 'cartegrise' || paiement.document === 'mutation')
               setMsgErr("Nous n'avons pas trouvé de Carte grise correspondante.");  
           else if(paiement.document === 'autorisation')
             setMsgErr("Nous n'avons pas trouvé d'autorisation de transport correspondante.");    
           else if(paiement.document === 'changementmodeexp')
             setMsgErr("Nous n'avons pas trouvé de Carte grise correspondante."); 
           else setMsgErr("Numéro de Chassis Non Trouvé.");
            setisCherche(false);
          }
        }
      
     }
  }
  const deleteElement = (elem) => {
    var charactere = '';
    for(var i = 0;i < elem.length - 1; i++){
       charactere += elem[i];
    }
    return charactere;
  }
  const hanleKeypUp = (e) => {
    let ancienNumero = '';
    if(e.key.charCodeAt(0) === 66 && paiement.ancienNumMat !== paiement.type_plaque + "-"){
      ancienNumero = deleteElement(paiement.ancienNumMat);
      setPaiement({...paiement,ancienNumMat: ancienNumero}); 
    }else if(e.key.charCodeAt(0) === 66 && paiement.ancienNumMat === paiement.type_plaque + "-"){
      setPaiement({...paiement,ancienNumMat: paiement.ancienNumMat});
    }
    else if((paiement.ancienNumMat + e.key).length === 8){
        ancienNumero = paiement.ancienNumMat;
        setPaiement({...paiement,ancienNumMat: ancienNumero + "-" + e.key});
    }
    else if((paiement.ancienNumMat + e.key).length > 8){
        ancienNumero = paiement.ancienNumMat + e.key.toUpperCase();
        setPaiement({...paiement,ancienNumMat: ancienNumero});
    }
   else {
    if((e.key.charCodeAt(0) >= 48 && e.key.charCodeAt(0) <= 57)){
       ancienNumero = paiement.ancienNumMat + e.key;
       if(e.key.charCodeAt(0) !== 66){
        ancienNumero = paiement.ancienNumMat + e.key;
        if(ancienNumero.length === 7)
          setPaiement({...paiement,ancienNumMat: ancienNumero + "-"});
        else {
            ancienNumero = paiement.ancienNumMat + e.key;
             setPaiement({...paiement,ancienNumMat: ancienNumero});
          }
      }else{ 
         if(paiement.ancienNumMat !== paiement.type_plaque + "-"){
          ancienNumero = deleteElement(paiement.ancienNumMat);
          setPaiement({...paiement,ancienNumMat: ancienNumero});
         }
      }
    }
    }
  //   ancienNumero = paiement.ancienNumMat + e.key.toUpperCase();
  // setPaiement({...paiement,ancienNumMat: ancienNumero });
}
  
 //console.log(paiement)
  useEffect(() => {
    if(elementsData?.categories)
      setCategories(elementsData.categories); 
  
    if(elementsData?.autorisations)
       setAutorisations(elementsData.autorisations.filter((p) => p.categorie_id === parseInt(paiement.categorie_id)));

     reset(paiement);
     setCartegrises([]);setVignettes([]);
     let modeImma = 1;
     if(paiement.modeImma !== "Immatriculation")
        modeImma = 2;
     
     if(elementsData?.typeCarteGrise){
        const cartegrise = elementsData.typeCarteGrise.filter(t => t.categorie_id === parseInt(paiement.categorie_id) && t.type === parseInt(modeImma));
        if(Array.isArray(cartegrise) && cartegrise.length > 0){
          setCartegrises(cartegrise.map((cg) =>{
            return expression(cg)
          }));
        }
      }
    
      if(elementsData?.typeVignette){
        const vignette = elementsData.typeVignette.filter(v => v.typecg_id === parseInt(paiement.categorie_id));
        if(Array.isArray(vignette) && vignette.length > 0){
          setVignettes(vignette);
     
        }
    }

  },[activeStep]);
  // console.log(autorisations)
  console.log(paiement)
  return (
    <>
      <div className="input-group">
          
         <div className="checkbox" style={{display:'flex'}}>
           <label>
              <input type="checkbox" name='isRenouvellement1' onChange={handleInput}
              checked={paiement.isRenouvellement1}
              /> Renouvellement  Nouvelles Immatriculations
            </label>
            <label>
              <input type="checkbox" style={{marginLeft:'15px'}}  checked={paiement.isRenouvellement2} name='isRenouvellement2' onChange={handleInput}
              /> Renouvellement  vignettes et autorisations de transport
            </label>
          </div>
      </div>
      <div className="step-component">
        <DocumentTitle title="Vignette|Autorisation|Mutation" />
        <h4>Informations</h4>
        {paiement.isRenouvellement1 && <form onSubmit={handleSubmit(nextStep)}>
          <div className="input-group">
            <div>
              <label>
                Type de document
                <select name="document" id="document" value={paiement.document}
                  {...register('document', {
                    onChange: (e) => {
                      handleInput(e)
                    },
                    validate: (value) => value != '' 
                    },
                  )}
                >
                  <option value="">Type de document</option>
                  <option value="duplicata">Duplicata</option>
                  <option value="vignette">Vignette</option>
                  <option value="cartegrise">Carte Grise</option>
                  <option value="autorisation">Autorisation de transport</option>
                  <option value="mutation">Mutation de Propriété</option>
                  <option value="changementmodeexp">Changement du Mode d'exploitation</option>
                </select>
                {errors.operation && (
                  <span className="error-msg">Selectionner le Type de Document.</span>
                )}

              </label>
              <br />
              {paiement.document === 'duplicata' && <Duplicata register={register} chercher={chercher} paiement={paiement} setPaiement={setPaiement}
                handleInput={handleInput} errors={errors} msgErr={msgErr} isCherche={isCherche} setIsfound={setIsfound}
                selectedCartegrise_id={selectedCartegrise_id} selectedAutorisation_id={selectedAutorisation_id}
                setDocumentError={setDocumentError}  documentError={documentError} 
                />}
              {paiement.document === 'vignette' && <Vignette register={register} chercher={chercher} paiement={paiement} 
                handleInput={handleInput} errors={errors}  isfound={isfound} setIsfound={setIsfound} msgErr={msgErr} setMsgErr={setMsgErr}/>}   
              {(paiement.document === 'cartegrise' || paiement.document === 'mutation') && <CarteGrise register={register} chercher={chercher} paiement={paiement} 
                handleInput={handleInput} errors={errors}  isfound={isfound} setIsfound={setIsfound} msgErr={msgErr} setMsgErr={setMsgErr}/>}      
              {paiement.document === 'autorisation' && <Autorisation register={register} chercher={chercher} paiement={paiement} 
                handleInput={handleInput} errors={errors}  isfound={isfound} setIsfound={setIsfound} msgErr={msgErr} setMsgErr={setMsgErr}/>} 

              {paiement.document === 'changementmodeexp' && <ModeExploitation register={register} chercher={chercher} 
                paiement={paiement} setPaiement = {setPaiement} handleInput={handleInput} errors={errors}  isfound={isfound} setIsfound={setIsfound}
                autorisations={elementsData.autorisations} msgErr={msgErr} setMsgErr={setMsgErr}
                />}           
            </div>
          </div>  
          <div className="buttons">
            {/* {paiement.operation === 'Renouvellement' &&
                <button type="submit" disabled={isfound?false:true}>Suivant</button>
            } */}
          
            <button type="submit" disabled={isfound?false:true}>Suivant</button>
          </div>
        </form>}
        {paiement.isRenouvellement2 && <form onSubmit={handleSubmit(nextStep)}>

          <div className="input-group">
            <label>
              Type de client
              <select
                name="typeClient"
                id="typeClient"
                {...register("typeClient", {
                  onChange: (e) => {
                    handleInput(e);
                  },
                  validate: (value) => value != "",
                })}
                value={paiement.typeClient}
              >
                <option value="" key={"typeClient"}>
                  Selectionnez le type de client
                </option>
                <option value="Particulier" key={"particulier"}>
                  Particulier
                </option>
                <option value="Société" key={"societe"}>
                  Société
                </option>
              </select>
              {errors.typeClient && (
                <span className="error-msg">Ce champ est obligatoire.</span>
              )}
            </label>
             <label>Type de plaque
              <select
                name="type_plaque"
                id="type_plaque"
                {...register("type_plaque", {
                  onChange: (e) => {
                    handleInput(e);
                  },
                  validate: (value) => value != "",
                })}
                value={paiement.type_plaque}
              >
                <option value="" key={"type_plaque"}>
                  Selectionnez le type de plaque
                </option>
                <option value="RC" key={"rc"}>
                  RC
                </option>
                <option value="EP" key={"ep"}>
                  EP
                </option>
                <option value="IT" key={"it"}>
                  IT
                </option>
              </select>
              {errors.type_plaque && (
                <span className="error-msg">Ce champ est obligatoire.</span>
              )}
             </label>
             <label>N°Chassis
                 <input type="text"
                  value={paiement?.numChassis?paiement?.numChassis:''}
                  placeholder="Numéro de chassis"
                  name="numChassis"
                  id="numChassis"
                  {...register("numChassis", {
                    onChange: (e) => {
                      handleInput(e);
                    },
                    required: true,
                    maxLength: 17,
                    minLength: 2,
                  })}
                 />
               {errors.numChassis && errors.numChassis?.type === "required" && (
                 <span className="error-msg">Ce champ est obligatoire.</span>
               )}
               {errors.numChassis && errors.numChassis?.type === "minLength" && (
                <span className="error-msg">
                  Ne peut pas être inférieur à deux caractères.
                </span>
               )}
              {errors.numChassis && errors.numChassis?.type === "maxLength" && (
               <span className="error-msg">
                 Ne peut pas être superieur à dix sept (17).
               </span>
              )}
             </label>
          
          </div>
          <div className="input-group">
            <label>
               {paiement.typeClient === 'Société'&& "Nom de la société"}
               {paiement.typeClient === 'Particulier'&& "Prenom et Nom du Client"}
               {paiement.typeClient === "" && "Type de Client"}
               <input type="text" 
               value={paiement.fullName}
               name="fullName"
               placeholder="Prenom et Nom du client"
               id="fullName"
               {...register("fullName", {
                 onChange: (e) => {
                   handleInput(e);
                 },
                 required: true,
                 maxLength: 150,
                 minLength: 2,
               })}
               />
             {errors.fullName && errors.fullName?.type === "required" && (
                <span className="error-msg">Ce champ est obligatoire.</span>
              )}
              {errors.fullName && errors.fullName?.type === "minLength" && (
                <span className="error-msg">
                  Ne peut pas être inférieur à 2 caractères.
                </span>
              )}
              {errors.fullName && errors.fullName?.type === "maxLength" && (
                <span className="error-msg">
                  Ne peut pas être supérieur à 150 caractères.
                </span>
              )}
            </label>
            <label>Ancien N° d'immatri.
                 <input type="text" value={paiement?.ancienNumMat? paiement?.ancienNumMat:''}
                  name="ancienNumMat"  
                  placeholder="ex: RC-1234-A"
                  onKeyUp={hanleKeypUp}
                  {...register('ancienNumMat', {
          
                   required:true,
                    pattern:{
                        value: /[A-Z]{2,3}-[0-9]{4}-[A-Z]{1,2}/,
                        message:"Respectez le format d'immatriculation(Ex:RC-xxxx-xx)"
                    }
                   },
                  )}
                 />
                {errors.ancienNumMat && errors.ancienNumMat?.type === "required" && (
                 <span className="error-msg">Ce champ est obligatoire.</span>
                )}
                 {errors.ancienNumMat && (
                     <span className="error-msg">{errors.ancienNumMat.message}</span>
                  )}
            </label>
          </div>
          <div className="input-group">
             <label>N°Téléphone
                 <input type="text"  value={paiement?.tel?paiement.tel:''}
                   placeholder="Numéro de téléphone(ex: 620000000)"
                   name="tel"
                   id="tel"
                   {...register("tel", {
                   onChange: (e) => {
                     handleInput(e);
                   },
                   required: true,
                   maxLength: 9,
                   minLength: 5,
                   pattern: /6[0-9]{8}$/g,
                  })}
                 />
               {errors.tel && errors.tel?.type === "required" && (
                <span className="error-msg">Ce champ est obligatoire.</span>
               )}
               {errors.tel && errors.tel?.type === "minLength" && (
                <span className="error-msg">
                  Ne peut pas être inférieur à 5 caractères.
                </span>
               )}
               {errors.tel && errors.tel?.type === "maxLength" && (
                <span className="error-msg">
                  Ne peut pas être supérieur à 9 caractères.
                </span>
               )}
               {errors.tel && errors.tel.type === "pattern" && (
                  <span role="alert" className="error-msg">
                    Format invalide.ex: 620000000
                  </span>
                )}
             </label>
            <label>
              Mode d'exploitation
              <select
                name="modeExpChange"
                id="modeExpChange"
                {...register("modeExpChange", {
                  onChange: (e) => {
                    handleInput(e);
                  },
                  validate: (value) => value != "",
                })}
                value={paiement.modeExpChange}
              >
                <option value="">Selectionner l'Usage</option>
                <option value="Personnel">Usage personnel</option>
                <option value="Transport">Transport</option>
              </select>
              {errors.modeExpChange && (
                <span className="error-msg">Ce champ est obligatoire.</span>
              )}
            </label>
            <label>
               Categorie de Vehicule
             <select
                placeholder="Categorie de vehicule"
                name="categorie_id"
                id="categorie_id"
                {...register("categorie_id", {
                  onChange: (e) => {
                    handleInput(e);
                  },
                  validate: (value) => parseInt(value) != 0,
                })}
                value={paiement.categorie_id}
              >
              <option value="0"> Categorie de Vehicule</option>
                {elementsData &&
                  elementsData.categories.map((categorie) => (
                    <option
                      key={categorie.categorie_id}
                      value={categorie.categorie_id}
                    >
                    {categorie.nomCategorie}
               </option>
                ))}
             </select>
            {errors.categorie_id && (
              <span className="error-msg">Ce champ est obligatoire.</span>
            )}
      
            </label>
        
        
          </div>
          <div className="input-group">
          {parseInt(paiement.vignette_id) !== 6 && 
             <label>
               Type de vignette
             <select
              placeholder="Type de vignette"
              name="vignette_id"
              id="vignette_id"
              {...register("vignette_id", {
                onChange: (e) => {
                  handleInput(e);
                },
                validate: (value) => parseInt(value) !== 0 || paiement.modeExpChange !== "Personnel",
              })}
              value={paiement.vignette_id}
            >
              <option value={0}>Type de vignette</option>
              {vignettes &&
                vignettes.map((typecarte) => (
                  
                  <option key={typecarte.typevg_id} value={typecarte.typevg_id}>
                    {typecarte.nomType}
                  </option>
                ))}
             </select>
              {errors.vignette_id && (
              <span className="error-msg">Ce champ est obligatoire.</span>
               )}
             </label>
            }
           {(paiement?.modeExpChange === 'Personnel' || paiement?.modeExpChange === '') && <><label></label><label></label></>}
           {paiement.modeExpChange === "Transport" && (
            <>
            <label>
              Autorisation de Transport
              <select
                value={paiement.autorisation_id}
                name="autorisation_id"
                id="autorisation_id"
                {...register("autorisation_id", {
                  onChange: (e) => {
                    handleInput(e);
                  },
                  validate: (value) => (parseInt(value) !== 0 || (paiement.vignette_id !== 0 && paiement.vignette_id !== '')),
                })}
              >
                <option value={0}>Autorisation de Transport</option>
                {autorisations &&
                  autorisations.map((autorisation) => (
                    <option
                      key={autorisation.autorisation_id}
                      value={autorisation.autorisation_id}
                    >
                      {autorisation.nomAutorisation}
                    </option>
                  ))}
              </select>
              {errors.autorisation_id && (
                <span className="error-msg">Ce champ est obligatoire.</span>
              )}
            </label>
             <label></label>
           
            </>
            )}
          </div>
          <div className="buttons">
            <button type="submit">Suivant</button>
          </div>
        </form>}
      </div>
    </>
  );
};

export default StepOne;
