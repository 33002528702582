import React, { useContext, useEffect, useState } from "react";
import CartTable from "../CartTable/CartTable";
import { ElementContext } from "../../services/Context/Context";
import { expression, formatStringNumber, objecttoFormData } from "../../services/Helpers/fonctions";
import { ToWords } from 'to-words';
import Api from "../../services/Api";
import Erreurs from "../Erreurs/Erreurs";
import { useRecoilState } from "recoil";
import { loadingState } from "../../recoil/atoms/loadingAtom";
import Duplicata from "./Duplicata/Duplicate";
import toast from "react-hot-toast";
const StepTwo = ({ prevStep, nextStep,paiement,activeStep,setActiveStep,paiementInfo,setPaiementInfo,setMsgErr}) => {
  //const [paiementInfo,setPaiementInfo] = useState() //mapping des champs pour l'afficher dans CartTable
  const {elementsData} = useContext(ElementContext);

  const [titre,setTitre] = useState();
  const[sumMontant,setSumMontant] = useState(0);
  const [erreurs,setErreurs] = useState([]);
  const [isLoading,setIsLoading] = useRecoilState(loadingState);
  
  const api = new Api();
  const toWords = new ToWords({
    localeCode: 'fr-FR',
    converterOptions: {
      currency: false,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
    }
  });
  useEffect(() => {
   
    if(paiement.fullName == "")
      setActiveStep(1);   
    let montant = 0;setSumMontant(0);
    if(paiement.document === 'vignette')
       setTitre("d'une Vignette");
    else if(paiement.document === 'autorisation') 
       setTitre("d'une autorisation de Transport");
   else if(paiement.document === 'mutation')  
       setTitre("d'une Mutation");
 

    const categorie = elementsData.categories.filter(c => c.categorie_id === parseInt(paiement.categorie_id));
    //console.log(paiement);
    if(paiement.categorie_id == 4)
      montant = 30000;
    else if(paiement.categorie_id == 3 || paiement.categorie_id == 2)
      montant = 20000;
    else if(paiement.categorie_id == 1)  
      montant = 10000;

    switch(paiement.document){
      
      case 'duplicata':{
        const cartegrises = elementsData.typeCarteGrise.filter((c) => c.typecg_id === parseInt(paiement.cartegrise_id));
     
        const vignette = elementsData.typeVignette.filter((c) => c.typevg_id === parseInt(paiement.vignette_id));
        const autorisation = elementsData.autorisations.filter((a) => a.autorisation_id === parseInt(paiement.autorisation_id));
        console.log(paiement);
        if(Array.isArray(cartegrises) && cartegrises.length > 0 || vignette.length > 0 || autorisation.length > 0){
         setPaiementInfo({
           ...paiementInfo,
           document:paiement.document,
           cartegrise:cartegrises.length > 0?cartegrises[0]:'',
           vignette:vignette.length > 0 ?vignette[0]:'',
           autorisation:autorisation.length > 0 ?autorisation[0]:'',
           commission:montant,
           expressionCg:cartegrises.length > 0 ?expression(cartegrises[0]).expression:'',
           categorie:categorie[0]?.nomCategorie,

         });
    
         setSumMontant(parseFloat(cartegrises[0]?.montant?cartegrises[0]?.montant:0) 
         + parseFloat(vignette[0]?.montant?vignette[0]?.montant:0)+ parseInt(autorisation[0]?.montant?autorisation[0]?.montant:0) + parseFloat(montant));
        }
        break;
      }
      case "vignette": {
          if(paiement.vignette_id === 0){
             toast.error("Vous ne disposez pas de carte grise");setActiveStep(1);
           }
                 const vignette = elementsData.typeVignette.filter(c => c.typevg_id === parseInt(paiement.vignette_id));
    
                  setPaiementInfo({...paiementInfo,
                    document:paiement.document,
                    vignette:vignette[0],
                    cartegrise:'',
                    nomCategorie:categorie[0]?.nomCategorie,
                    autorisation:'',
                    commission:montant
                  })
                  setSumMontant(parseFloat(vignette[0]?.montant) + parseFloat(montant));
              
                break; 
           
      }
      case "autorisation":{
             const autorisation = elementsData.autorisations.filter(a => a.autorisation_id == paiement.autorisation_id);
          
             setPaiementInfo({...paiementInfo,
              document:paiement.document,
              vignette:'',
              cartegrise:'',
              autorisation:autorisation[0],
              nomCategorie:categorie[0]?.nomCategorie,
              commission:montant
            })
            setSumMontant(parseFloat(autorisation[0]?.montant) + parseFloat(montant));
        
          break; 
                
      } 

     case "changementmodeexp":{
        let cartegrise = [];let modeImma = 1;let autorisation = [];
        let vignette = [];  
      
        if(paiement.modeImma === "ReImmatriculation")
           modeImma = 2;
         if(paiement.modeExpChange === "Personnel"){
            //  console.log(paiement)
             vignette = elementsData.typeVignette.filter(c => c.typevg_id === parseInt(paiement.vignette_id));
         
              cartegrise = elementsData.typeCarteGrise.filter((c) => c.typecg_id === parseInt(paiement.cartegrise_id) && c.type === parseInt(modeImma));
       
              if(paiement.cartegrise_id === 5 || paiement.cartegrise_id === 6 ){
                montant = 0; 
              }
            
              autorisation = elementsData.autorisations.filter((a) => a.autorisation_id === parseInt(paiement.autorisation_id));
         }else if(paiement.modeExpChange === "Transport"){
            if(paiement.modeImma !== 'Immatriculation')
              modeImma = 2;
             autorisation = elementsData.autorisations.filter((a) => a.autorisation_id === parseInt(paiement.autorisation_id));
             vignette = elementsData.typeVignette.filter(c => c.typevg_id === parseInt(paiement.vignette_id));
             cartegrise = elementsData.typeCarteGrise.filter((c) => c.typecg_id === parseInt(paiement.cartegrise_id) && c.type === parseInt(modeImma));
         
              if(paiement.cartegrise_id === 5 || paiement.cartegrise_id === 6 ){
                montant = 0;
              }
         }
       
         if(paiement.vignette_id === 0 && paiement.autorisation_id === 0 && paiement.cartegrise_id === 0){
          setMsgErr("Nous n'avons pas trouvé de document valide correspondant.");setActiveStep(1);return;
         }
        
        setPaiementInfo({...paiementInfo,
         document:paiement.document,
         vignette:vignette.length > 0 ? vignette[0]:'',
         cartegrise:cartegrise.length > 0 ? cartegrise[0]:'',
         autorisation:autorisation.length > 0 ? autorisation[0]:'',
         categorie:categorie[0]?.nomCategorie,
         categorie_id:paiement.cartegrise_id,
         expressionCg:cartegrise.length >0 ?expression(cartegrise[0]).expression:'Non Trouvé',
         commission:montant
       })
       setSumMontant(parseFloat(autorisation[0]?.montant?autorisation[0]?.montant:0) + parseFloat(cartegrise[0]?.montant?cartegrise[0]?.montant:0)+parseFloat(montant));
   
     break; 
           
     } 
     case "cartegrise":{
        let modeImma = 1;
        if(paiement.cartegrise_id === 0){
          toast.error("Vous ne disposez pas de carte grise");setActiveStep(1);
         }
        if(paiement.modeImma !== 'Immatriculation')
          modeImma = 2;
          const cartegrise = elementsData.typeCarteGrise.filter((c) => c.typecg_id === parseInt(paiement.cartegrise_id) && c.type === parseInt(modeImma));
          if(paiement.cartegrise_id === 5 || paiement.cartegrise_id === 6 ){
             montant = 0;
          }
          //console.log(paiement)
          if(Array.isArray(cartegrise) && cartegrise.length > 0){
            setPaiementInfo({
              ...paiementInfo,
              document:paiement.document,
              autorisation:'',
              cartegrise:cartegrise[0],
              commission:montant,
              expressionCg:expression(cartegrise[0]).expression,
              categorie:categorie[0]?.nomCategorie,
              vignette:''
            });
            setSumMontant(parseFloat(cartegrise[0]?.montant) + parseFloat(montant));
  
       }
 
       break;
    }
   case "mutation":{
         const cartegrises = elementsData.typeCarteGrise.filter(c => c.typecg_id == paiement.cartegrise_id);
         if(paiement.cartegrise_id === 0){
          toast.error("Vous ne disposez pas de carte grise");setActiveStep(1);
         }
      
         if(Array.isArray(cartegrises) && cartegrises.length > 0){
          setPaiementInfo({
            ...paiementInfo,
            document:paiement.document,
            cartegrise:cartegrises[0],
            autorisation:'',
            vignette:'',
            commission:montant,
            expressionCg:expression(cartegrises[0]).expression,
            categorie:categorie[0]?.nomCategorie,
          });
          setSumMontant(parseFloat(cartegrises[0]?.montant) + parseFloat(montant));
        }
         break;
     }      
   case "Achat":{
      if(paiement.vignette_id === 0 && paiement.autorisation_id === 0){
        toast.error("Vous devez acheter une vignette ou une autorisation de transport.");setActiveStep(1);
      }
          let autorisation = [];let vignette = [];let montantautorisation = 0;let montantvignette = 0;
          if(paiement.vignette_id !== 0){
            vignette = elementsData.typeVignette.filter(c => c.typevg_id === parseInt(paiement.vignette_id));
            if(vignette.length > 0)
              montantvignette = vignette[0]?.montant;
            
          }

          if(paiement.modeExpChange === "Transport"){
            autorisation = elementsData.autorisations.filter(a => a.autorisation_id == paiement.autorisation_id);
            if(autorisation.length > 0)
              montantautorisation = autorisation[0]?.montant 
          }
           setPaiementInfo({...paiementInfo,
             document:paiement.document,
             vignette:vignette.length > 0 ? vignette[0]:'',
             nomCategorie:categorie[0]?.nomCategorie,
             autorisation: autorisation.length > 0 ? autorisation[0]:'',
             commission:montant
           })
           setSumMontant(parseFloat(montantvignette) + parseInt(montantautorisation) + parseFloat(montant));
       
         break; 
    }               
    }
   
 
  },[paiement]);

  //console.log(sumMontant)
  const payer =  async () => {
    var formData = objecttoFormData(paiement);
    console.log(paiement);
    setErreurs([]);
    setIsLoading(true);
     const {status,messages,data} =  await api.apiData('post','/paiement/otherpaiement',formData);
    setIsLoading(false);  
    if( status !== 200 ){
      if(messages){
         setErreurs(messages);
        }
    }else{
      setPaiementInfo({...paiementInfo,qrcodepath:data.qrpath,reference:data.reference,paiement_id:data.paiement_id});
      setActiveStep(activeStep + 1);
    }

  }

  return (
    <div className="step-component step-two">
      <h4>Paiement {titre}</h4>
      <div className="table">
        <CartTable paiementInfo={paiementInfo} key='1000'/>
      </div>
      { !isNaN(sumMontant) &&  (
        <div className="total">
          <span>Total</span>
          <strong>{formatStringNumber(sumMontant)} fg</strong>
          <strong>{toWords.convert(sumMontant)}</strong> Francs Guinéens.
        </div>
      )
      }
      <div className="input-group">
          <div>
             <Erreurs validation={erreurs} />
          </div>
      </div>
      <div className="buttons">
        <button className="secondary" onClick={prevStep}>Précedent</button>
        <button className="primary" onClick={payer}>Payer</button>
      </div>
    </div>
  );
};

export default StepTwo;
