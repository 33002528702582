import { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import moment from 'moment';
const CarteGrise = ({register,chercher,paiement,handleInput,errors,isfound,setIsfound,msgErr,setMsgErr}) => {
    useEffect(() => {
        if(paiement.cartegrise_id !== 0){
            if(paiement.document === 'cartegrise'){
                if(moment(new Date()).format('YYYY-MM-DD HH:mm') < moment(paiement.date_exp).format('YYYY-MM-DD HH:mm')){  
                    setMsgErr("La date de la carte grise n'est pas expirée."); 
                    setIsfound(false);return;
                } else setIsfound(true);   
            
            }
        }else{
            setMsgErr("Aucune Carte grise correspondante trouvée."); 
            setIsfound(false);return;
        }
        if(paiement.numChassis === ""){
            setMsgErr(''); setIsfound(false);return;
         }
    },[paiement])

    return (
      <>
        <div className="input-group">
          <label>Numéro de Chassis
            <div className="search-field">
                <input type="text" placeholder="Saisir le Numéro de Chassis" 
                        name="numChassis" value={typeof paiement.numChassis !== 'number'?paiement.numChassis:'' } 
                        {...register('numChassis', {
                            onChange: (e) => {
                            handleInput(e)
                            },
                            required:true,minLength:8,maxLength:25},
                            
                        )}
                />
                <div className="search-icon" onClick={chercher}>
                    <FiSearch />
                </div>
              
            </div>
            { msgErr && <span className="error-msg">{msgErr}</span>}
            {errors.numChassis && errors.numChassis?.type === "required" && (
                    <span className="error-msg">Le numéro de Chassis est obligatoire.</span>
                )}
            {errors.numChassis && errors.numChassis?.type === "minLength" && (
                <span className="error-msg">Le caractère minimum est huit (8).</span>
            )}
             {errors.numChassis && errors.numChassis?.type === "maxLength" && (
                <span className="error-msg">Le caractère maximal est vingt cinq (25).</span>
             )}    
            </label>
            <label>{paiement.typeClient === 'Société' ? 'Nom de la Société' :'Prenom et Nom du client'}
               <strong style={{marginTop:'12px'}}>{paiement.fullName? paiement.fullName:'Non Fournie'}</strong>
            </label>
            <label>Téléphone
               <strong style={{marginTop:'12px'}}>{paiement.telephone?paiement.telephone:'Non Fournie'}</strong>
            </label>
        </div>
        <div className='input-group'>
            <label>Mode d'Immatriculation
                <strong style={{marginTop:'12px'}}>{paiement.modeImma?paiement.modeImma:'Non Fournie'}</strong>
            </label>
            <label>Mode d'Exploitation
               <strong style={{marginTop:'12px'}}>{paiement.modeExp?paiement.modeExp:'Non Fournie'}</strong>
            </label>
            <label>Categorie de Vehicule
                <strong style={{marginTop:'12px'}}>{paiement.nomCategorie?paiement.nomCategorie:'Non Fournie'}</strong>
            </label>
            <label>Type de Carte Grise
                <strong style={{marginTop:'12px'}}>{paiement.cartegrise ? paiement.cartegrise :'Non Fournie'}</strong>
            </label>
       </div>     
      </>
    );
}
export default CarteGrise;