import { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGridPremium, GridToolbar, frFR } from "@mui/x-data-grid-premium";

import NewWardModal from "../../components/NewWardModal/NewWardModal";
import EditWardModal from "../../components/EditWardModal/EditWardModal";
import Api from "../../services/Api";
import Erreurs from "../../components/Erreurs/Erreurs";
import { UserContext } from "../../services/Context/Context";
import Swal from "sweetalert2";
import { useRecoilState } from "recoil";
import { loadingState } from "../../recoil/atoms/loadingAtom";
const Ward = () => {
  const [isNewWardModalOpen, setIsNewWardModalOpen] = useState(false);
  const [isEditWardModalOpen, setIsEditWardModalOpen] = useState(false);
  const [selectedWard, setSelectedWard] = useState(null);
  const[wardData,setWardData] = useState([]);
  const [erreurs,setErreurs] = useState([]);
  const [nbrequartier,setNbrequartier] = useState(0);
  const [prefectures,setPrefectures] = useState([]);
  const [isLoading,setIsLoading] = useRecoilState(loadingState);
  const [isDelete,setIsDelete] = useState(false);

  const {decoupage} = useContext(UserContext);
  const api = new Api();
  const handleDelete = (data) => {
    setErreurs([]);
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'de supprimer ce quartier/district.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui,de supprimer',
      cancelButtonText: "Annuler"
    }).then((result) => {
      if(result.isConfirmed){
        setIsDelete(!isDelete)
        setIsLoading(true);
          api.apiData('get',`/decoupage/delete/${data.id}`).then((resp) => {
          if(resp?.status !== 200)
              setErreurs(resp?.messages);
          })
        setIsLoading(false);
      }
    });
  }
// ? Agencies data
 const Columns = [
    {
      field: "num",
      headerName: "Numero",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "prefecture",
      headerName: "Prefecture",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "commune",
      headerName: "Commune",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "quartier",
      headerName: "Quartier",
      flex: 1,
      minWidth: 150,
    },
    
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <div className="options">
            <button onClick={() => {setSelectedWard(params.row); setIsEditWardModalOpen(true);}}>Modifier</button>
            <button className="delete" onClick={() => handleDelete(params.row)}>Supprimer</button>
          </div>
        );
      },
    },
  ];
  

   useEffect(() => {
     const getLocalites = async () => {
           setErreurs([]);
          const{status,messages,localites} = await api.apiData('get','decoupage/getlocalite');
          if(status === 200){
             let num = 0;
              setWardData(localites.map((localite) => {
                 num +=1;
                 return {
                   num:num,
                   id:localite.quartier_id,
                   prefecture_id:localite.prefecture_id,
                   commune_id:localite.commune_id,
                   prefecture:localite.prefecture,
                   commune:localite.commune,
                   quartier:localite.quartier
                 }
              })); 
              setNbrequartier(num);
          }else{
              setErreurs(messages);
          }
      }
      getLocalites();
      
   },[isDelete,isNewWardModalOpen,isEditWardModalOpen]);
  // console.log(wardData)
  return (
    <div className="payment-list page">
      <NewWardModal
        isOpen={isNewWardModalOpen}
        setIsOpen={setIsNewWardModalOpen}
        prefectures = {decoupage?.prefectures}
        communes = {decoupage?.communes}
      />
      <EditWardModal
        isOpen={isEditWardModalOpen}
        setIsOpen={setIsEditWardModalOpen}
        selectedWard={selectedWard}
        setSelectedWard ={setSelectedWard}
        prefectures = {decoupage?.prefectures}
        communes = {decoupage?.communes}
        quartiers = {decoupage?.quartiers}
      />
      <Erreurs validation={erreurs} /> 
      <div className="header space-between">
        <div>
          <h3>Liste des quartiers</h3>
          <p>{nbrequartier} quartiers</p>
        </div>
        <button className="primary" onClick={() => setIsNewWardModalOpen(true)}>
          Nouveau quartier
        </button>
      </div>
      <div className="array">
        <Box sx={{ height: 579, width: "100%" }}>
          <DataGridPremium
            sx={{ borderRadius: 0 }}
            density="compact"
            components={{ Toolbar: GridToolbar }}
            rows={wardData}
            columns={Columns}
            autoPageSize
            pagination
            disableSelectionOnClick
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          />
        </Box>
      </div>
    </div>
  );
};

export default Ward;
