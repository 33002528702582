import moment from 'moment/moment'
import { useEffect,useState } from 'react';
import { default as ReactSelect } from "react-select";
import { FiSearch } from 'react-icons/fi';
import { components } from "react-select";
const Duplicata = ({register,chercher,paiement,setPaiement,handleInput,errors,msgErr,isCherche,setIsfound,selectedCartegrise_id,selectedAutorisation_id,setDocumentError,documentError}) => {
    // console.log(paiement.NomAutorisation)
    const now = new Date();
    const[isAutorisationValid,setIsAutorisationValid] = useState();
    const[isVignetteValid,setIsVignetteValid] = useState();
    const[selectDocuments,setSelectedDocuments] = useState([]);
    const[iscartegrise,setIscartegrise] = useState(false);
    const[isautorisation,setIsautorisation] = useState(false);
    const[tous,setTous] = useState(false);
    const [documents,setDocuments] = useState([
        {value:1,label:"Carte Grise"},{value:2,label:"Autorisation de Transport"},{value:3,label:"Tous les deux"}
    ])
    useEffect(() => {
        // setPaiement({...paiement,autorisation_id:moment(now).isAfter(paiement.dateExpAu,'year')?paiement.autorisation_id:0,
        //     vignette_id:moment(now).isAfter(paiement.dateExpVg,'year')?paiement.vignette_id:0});
        let iscartegrise1 = 0;
        let autorisation1 = 0;
        let tous1 = 0;
        setPaiement({...paiement,autorisation_id: autorisation1,cartegrise_id:iscartegrise1,vignette_id:0});
        setIsAutorisationValid(moment(now).isAfter(paiement.dateExpAu,'year'));
        setIsVignetteValid(moment(now).isAfter(paiement.dateExpVg,'year'));
        const docs = [];let isTous = false;
        let transport_id = 0;let cartegrise_id = 0; 
        if(typeof paiement.selectdocuments !== 'undefined'){
          if(paiement?.selectdocuments.length > 0 && Array.isArray(paiement.selectdocuments)){
             paiement.selectdocuments.forEach((valeur,index) => {
                if(valeur.label === "Autorisation de Transport")
                   transport_id = selectedAutorisation_id;
                else if(valeur.label === 'Carte Grise')
                  cartegrise_id = selectedCartegrise_id;
                else if(valeur.label === 'Tous les deux'){
                    transport_id = selectedAutorisation_id;
                    cartegrise_id = selectedCartegrise_id;
                    isTous = true;setTous(true);
                }  

             });
             if(isTous)
                setDocuments([{value:3,label:"Tous les deux"}]);
             else {
                if(selectedAutorisation_id === 0){
                    setDocuments([{value:1,label:"Carte Grise"},{value:3,label:"Tous les deux"}]);
                 }
                 if(selectedCartegrise_id === 0)
                   setDocuments([{value:2,label:"Autorisation de Transport"},{value:3,label:"Tous les deux"}]);
                 if(selectedCartegrise_id !== 0 && selectedAutorisation_id !== 0)  
                   setDocuments([{value:1,label:"Carte Grise"},{value:2,label:"Autorisation de Transport"},{value:3,label:"Tous les deux"}])
            }  
          }else {

             if(selectedAutorisation_id === 0){
                setDocuments([{value:1,label:"Carte Grise"},{value:3,label:"Tous les deux"}]);
             }
             if(selectedCartegrise_id === 0)
               setDocuments([{value:2,label:"Autorisation de Transport"},{value:3,label:"Tous les deux"}]);
             if(selectedCartegrise_id !== 0 && selectedAutorisation_id !== 0)  
               setDocuments([{value:1,label:"Carte Grise"},{value:2,label:"Autorisation de Transport"},{value:3,label:"Tous les deux"}])
          }
        //   console.log(transport_id)
          setPaiement({...paiement,autorisation_id:transport_id,cartegrise_id:cartegrise_id,vignette_id:0}); 
        }
       
    },[isCherche,paiement?.selectdocuments]);

    // console.log(selectedCartegrise_id)
   const handleInput2 = (e) => {
      setTous(false);setIsautorisation(false);setIscartegrise(false);
      let isTous = false;setDocumentError('')
      e.forEach((valeur,index) => {
         if(parseInt(valeur.value) === 3){
             isTous = true;
             if(e.length == 1)
               setPaiement({...paiement,selectdocuments:e})
            return;   
         }
      });
      
      if(isTous == false)
        setPaiement({...paiement,selectdocuments:e})
   }
    return(
        <>
         <div className="input-group">
            <label>Numéro de Chassis
                <div className="search-field">
                    <input type="text" placeholder="Saisir le Numéro de Chassis" 
                            name="numChassis" value={paiement.numChassis} 
                            {...register('numChassis', {
                                onChange: (e) => {
                                handleInput(e)
                                },
                                required:true,minLength:8,maxLength:25},
                                
                            )}
                    />
                    <div className="search-icon" onClick={chercher}>
                        <FiSearch />
                    </div>
                  
                </div>
                {msgErr && <span className="error-msg">{msgErr}</span>}
                {errors.numChassis && errors.numChassis?.type === "required" && (
                      <span className="error-msg">Le numéro de Chassis est obligatoire.</span>
                    )}
                {errors.numChassis && errors.numChassis?.type === "minLength" && (
                    <span className="error-msg">Le caractère minimum est huit (8).</span>
                )}
                {errors.numChassis && errors.numChassis?.type === "maxLength" && (
                    <span className="error-msg">Le caractère maximal est vingt cinq (25).</span>
                 )}    
            </label>
            <label>{paiement.typeClient === 'Société' ? 'Nom de la Société' :'Prenom et Nom du client'}
               <strong style={{marginTop:'12px'}}>{paiement.fullName? paiement.fullName:'Non Fournie'}</strong>
            </label>
            <label>Téléphone
               <strong style={{marginTop:'12px'}}>{paiement.telephone?paiement.telephone:'Non Fournie'}</strong>
            </label>
         </div>
         <div className='input-group'>
            <label>Mode d'Immatriculation
                <strong style={{marginTop:'12px'}}>{paiement.modeImma?paiement.modeImma:'Non Fournie'}</strong>
            </label>
            <label>Mode d'Exploitation
               <strong style={{marginTop:'12px'}}>{paiement.modeExp?paiement.modeExp:'Non Fournie'}</strong>
            </label>
            <label>Categorie de Vehicule
                <strong style={{marginTop:'12px'}}>{paiement.nomCategorie?paiement.nomCategorie:'Non Fournie'}</strong>
            </label>
            {paiement.cartegrise ?
            <label>Type de Carte Grise
                 <strong style={{marginTop:'12px'}}>{paiement.cartegrise  }</strong>
            </label>:''}
          
         </div>
         <div className='input-group'>
            {isAutorisationValid && (<label>Autorisation de Transport
               <strong style={{marginTop:'12px'}}>{paiement.NomAutorisation ? paiement.NomAutorisation :'Non Fournie'}</strong>
              </label>)}
           {isVignetteValid &&(
            <label>Type de Vignette
                <strong style={{marginTop:'12px'}}>{paiement.vignette ? paiement.vignette :'Non Fournie'}</strong>
            </label>)
            
            }
            <label>Type de plaque
                <strong>{paiement.type_plaque?paiement.type_plaque:'Non fournie'}</strong>
            </label>
           
            <label>Documents
                 <ReactSelect options={documents}  
                    isMulti
                    name="documents"
                    className="privileges basic-multi-select"
                    placeholder= "Choisissez le type de document"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={true}
                    allowSelectAll={true}
                    value={paiement.selectdocuments}
                    onChange={(e) => handleInput2(e)}
                />
                {documentError && <span className="error-msg">{documentError}</span>}
            </label>
            
           
         </div>    
      </>
    )
}
export default Duplicata;