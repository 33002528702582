export const memoirecode = [
    {signe:'<',
    signification:'Inférieur'
    },
    {
     signe:'>',
     signification:'Supérieur'
    },
    {
      signe:'<=',
      signification:'Inférieur égal'
    },
    {
        signe:'>=',
        signification:'Supérieur égal'
    },
    {
        signe:'!',
        signification:'Compris entre'
    },
    {
        signe:'!=',
        signification:'différent de'
    },
];