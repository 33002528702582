import { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import moment from 'moment';
const Autorisation = ({register,chercher,paiement,handleInput,errors,isfound,setIsfound,msgErr,setMsgErr}) => {
   
    useEffect(() => {
      
        if( paiement.autorisation_id !== 0){
            if(paiement.date_exp){
                if(typeof paiement.date_exp !== 'undefined'){
                    if(moment(new Date()).format('YYYY-MM-DD HH:mm') < moment(paiement.date_exp).format('YYYY-MM-DD HH:mm')){
                        setMsgErr("La date de l'autorisation de transport n'est pas expirée.");
                        setIsfound(false);return;  
                    } else setIsfound(true);   
                }
            }
       }
       if(paiement.numChassis === ""){
          setMsgErr('');return;
       }
    //    if(paiement.NomAutorisation === '' || typeof paiement.NomAutorisation === 'undefined'){
    //     setMessage("Nous n'avons pas trouvé d'autorisation de transport correspondante.");
    //   }else setIsfound(true);  
    
    },[paiement]);
    //console.log(paiement )
    return(
      <>
          <div className="input-group">
            <label>Numéro de Chassis
            <div className="search-field">
                <input type="text" placeholder="Saisir le Numéro de Chassis" 
                        name="numChassis" value={typeof paiement.numChassis !== 'number' ?paiement.numChassis:'' } 
                        {...register('numChassis', {
                            onChange: (e) => {
                            handleInput(e)
                            },
                            required:true,minLength:8,maxLength:25},
                            
                        )}

                />
                <div className="search-icon" onClick={chercher}>
                    <FiSearch />
                </div>
              
            </div>
            { msgErr && <span className="error-msg">{msgErr}</span>}
            {errors.numChassis && errors.numChassis?.type === "required" && (
                    <span className="error-msg">Le numéro de Chassis est obligatoire.</span>
                )}
            {errors.numChassis && errors.numChassis?.type === "minLength" && (
                <span className="error-msg">Le caractère minimum est huit (8).</span>
            )}
             {errors.numChassis && errors.numChassis?.type === "maxLength" && (
                <span className="error-msg">Le caractère maximal est vingt cinq (25).</span>
             )}    
            </label>
            <label>{paiement.typeClient === 'Société' ? 'Nom de la Société' :'Prenom et Nom du client'}
               <strong style={{marginTop:'12px'}}>{paiement.fullName? paiement.fullName:'Non Fournie'}</strong>
            </label>
            <label>Téléphone
               <strong style={{marginTop:'12px'}}>{paiement.telephone?paiement.telephone:'Non Fournie'}</strong>
            </label>
       </div>
       <div className='input-group'>
            <label>Mode d'Immatriculation
                <strong style={{marginTop:'12px'}}>{paiement.modeImma?paiement.modeImma:'Non Fournie'}</strong>
            </label>
            <label>Mode d'Exploitation
               <strong style={{marginTop:'12px'}}>{paiement.modeExp?paiement.modeExp:'Non Fournie'}</strong>
            </label>
            <label>Categorie de Vehicule
                <strong style={{marginTop:'12px'}}>{paiement.nomCategorie?paiement.nomCategorie:'Non Fournie'}</strong>
            </label>
            <label>Autorisation de Transport
                <strong style={{marginTop:'12px'}}>{paiement.NomAutorisation ? paiement.NomAutorisation :'Non Fournie'}</strong>
            </label>
       </div> 
      </>
    );
}
export default Autorisation;