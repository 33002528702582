import { useEffect, useRef, useState } from "react";
import { FaRegWindowClose } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import { useForm } from "react-hook-form";
import Erreurs from "../Erreurs/Erreurs";
import { useRecoilState } from "recoil";
import { loadingState } from "../../recoil/atoms/loadingAtom";
import Api from "../../services/Api";
import { objecttoFormData } from "../../services/Helpers/fonctions";
import toast from "react-hot-toast";
const useFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}
  return [ htmlElRef, setFocus ] 
}
const EditWardModal = ({ isOpen, setIsOpen, selectedWard,setSelectedWard,prefectures,communes,quartiers }) => {
  const modalRef = useRef();
  const [erreurs, setErreurs] = useState([]);
  const { register, handleSubmit, reset,formState: { errors } } = useForm({defaultValues:{selectedWard} });
  const [selectedCommunes,setSelectedCommunes] = useState([]);
  const [inputRef, setInputFocus] = useFocus();
  const [isLoading,setIsLoading] = useRecoilState(loadingState);
  const api = new Api();


  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setIsOpen(false);
    }
  };
  const updateQuartier = async () => {
    setIsLoading(true);
      var formData = new objecttoFormData(selectedWard);
      const {status,messages} = await api.apiData('post','/decoupage/update',formData);
      if(status === 200){
        toast.success("Modification effectuée avec succès.");
        setIsOpen(false);
      }
      else{
        setErreurs(messages);
      }
   setIsLoading(false);    
  }
  useEffect(() => {
    if(communes && selectedWard && quartiers){
     // console.log(communes)
     setIsLoading(true)
      const comms = communes.filter((c) => c.prefecture_id === selectedWard.prefecture_id);
      setSelectedCommunes(comms);
      setInputFocus();
     setIsLoading(false);
    }
  },[isOpen]);

  const handleInput = (e) => {   
    setSelectedWard({...selectedWard,[e.target.name]:e.target.value.toUpperCase()});

  }
  
  //console.log(selectedQuartier)
  return (
    <AnimatePresence>
      {isOpen ? (
        <div className="modal-component" onClick={closeModal} ref={modalRef}>
          <motion.div
            initial={{ opacity: 0, top: "30%" }}
            animate={{ opacity: 1, top: "50%" }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, top: "10%" }}
            className="modal"
          >
            <div className="close" onClick={() => setIsOpen(false)}>
              <FaRegWindowClose id="close-icon" />
            </div>
            <h4>Modifier un quartier</h4>
            <form onSubmit={handleSubmit(updateQuartier)}>
              <div className="input-group">
                <label>
                  Prefecture
                  <select name="prefecture_id" id="prefecture_id" value={selectedWard.prefecture_id} disabled={true}
                     {...register('prefecture_id', {
                      onChange: (e) => {
                        handleInput(e)
                      },
                      validate: (value) => value !=  0 
                      })
                    }
                   >
                    <option value={0}>Selectionner la Prefecture</option>
                    {prefectures && prefectures.map((prefecture) => {
                        return (<option value={prefecture.prefecture_id} key={Math.random()+"_"+prefecture.prefecture_id}>{prefecture.nom}</option>)
                    })}
                  </select>
                  {errors.prefecture_id && (
                      <span className="error-msg">Selectionner la Prefecture ou Commune de l'agence.</span>
                     )}
                </label>
                <label>
                  Commune
                  <select name="commune_id" id="commune_id" value={selectedWard.commune_id} disabled={true}
                    {...register('commune_id', {
                      onChange: (e) => {
                        handleInput(e)
                      },
                      validate: (value) => value !=  0 
                      })
                    }
                  >
                    <option value={0}>Selectionner la Commune</option>
                    {selectedCommunes && selectedCommunes.map((commune) => {
                       return (<option value={commune.commune_id} key={Math.random()+"_"+commune.commune_id}>{commune.nom}</option>)
                    })}
                  </select>
                </label>
              </div>
              <div className="input-group">
                <label>
                  Quartier
                  <input type="text" name="quartier" id="quartier" placeholder="Quartier" value={selectedWard.quartier}
                      autoFocus  
                     {...register("quartier", {
                     onChange: (e) => {
                      handleInput(e);
                       },
                     required: true,
                     minLength: 3,
                    })}
                  />
                 {errors.quartier && errors.quartier.type === "required" && (
                    <span role="alert" className="error-msg">
                      Champ obligatoire.
                    </span>
                  )}
                  {errors.quartier && errors.quartier.type === "minLength" && (
                    <span role="alert" className="error-msg">
                      Le nombre minimum de caractères est trois (3).
                    </span>
                  )}
                </label>
              </div>
               <Erreurs validation = {erreurs} /> 
              <button type="submit">Enregistrer</button>
            </form>
          </motion.div>
        </div>
      ) : null}
    </AnimatePresence>
  );
};

export default EditWardModal;